import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  ALL_PERMISSIONS,
  SET_ALL_PRODUCTS,
  SET_DUPLICATE_ORDER,
} from "../../constants/ActionTypes";

export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
  };
};

export const getCategoriesSuccess = (payload) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: payload,
  };
};

export const setAllPermissions = (payload) => {
  // console.log('payload of allPermissions', payload)
  return {
    type: ALL_PERMISSIONS,
    payload: payload,
  };
};

export const setProductsForCreate = (payload) => {
  // console.log("payload of setProductsForCreate", payload);
  return {
    type: SET_ALL_PRODUCTS,
    payload: payload,
  };
};

export const setDuplicateOrder = (payload) => {
  // console.log("payload of setDuplicateOrder", payload);
  return {
    type: SET_DUPLICATE_ORDER,
    payload: payload,
  };
};
