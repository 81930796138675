import {
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    ALL_PERMISSIONS,
    SET_ALL_PRODUCTS,
    SET_DUPLICATE_ORDER
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    categoriesList: [],
    allPermissions: [],
    allProducts: [],
    duplicateOrder: {}
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
  
      case GET_CATEGORIES: {
        return {
          ...state,
          loading: true
        }
      }

      case GET_CATEGORIES_SUCCESS: {
        return {
          ...state,
          categoriesList: action.payload,
        }
      }

      case ALL_PERMISSIONS: {
        return {
          ...state,
          allPermissions: action.payload
        }
      }

      case SET_ALL_PRODUCTS: {
        return {
          ...state,
          allProducts: action.payload
        }
      }

      case SET_DUPLICATE_ORDER: {
        return {
          ...state,
          duplicateOrder: action.payload
        }
      }
  
      
      default:
        return state;
    }
  }
  