import React, { useEffect } from "react";
import { Button, Input, message, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  forgetPassword,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      // console.log('showMessage', showMessage, alertMessage)
      setTimeout(() => {
        dispatch(hideMessage());
        if (
          alertMessage ===
          "A Password reset code has been sent to your email address."
        )
          history.push("/resetPassword");
      }, 100);
    }
    // if (authUser !== null) {
    //   history.push('/');
    // }
  });

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = ({ email, password }) => {
    // console.log("finish",values)
    let formData = new FormData();
    formData.append("email", email);
    console.log("formData", [...formData]);
    dispatch(showAuthLoader());
    dispatch(forgetPassword(formData));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="Forgot your password?" />
              </h1>
              <p>
                <IntlMessages id="Enter your email address to" />
              </p>
              <p>
                <IntlMessages id="retrieve your password" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="logo"
                style={{ width: "100px" }}
                src={require("assets/images/maskGroup19/MaskGroup19.png")}
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                // initialValue="demo@example.com"
                // initialValue="admin@jmtrophies.com"
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="Send" />
                </Button>
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
