import React, { createContext, useState } from "react";

export const GlobalDataContext = createContext({
  contactQueriesCount: 0,
  setContactQueriesCount: () => {},
});

export default function GlobalContext({ children }) {
  const [contactQueriesCount, setContactQueriesCount] = useState(0);

  return (
    <GlobalDataContext.Provider
      value={{
        contactQueriesCount,
        setContactQueriesCount,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}
