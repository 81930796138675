// Customizer const
export const THEME_TYPE = "THEME_TYPE";
export const THEME_TYPE_LITE = "THEME_TYPE_LITE";
export const THEME_TYPE_DARK = "THEME_TYPE_DARK";
export const THEME_TYPE_SEMI_DARK = "THEME_TYPE_SEMI_DARK";

export const MONTH_ENUMS = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const YEAR_ENUMS = [
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
  { value: 2026, label: "2026" },
  { value: 2027, label: "2027" },
  { value: 2028, label: "2028" },
  { value: 2029, label: "2029" },
  { value: 2030, label: "2030" },
  { value: 2031, label: "2031" },
  { value: 2032, label: "2032" },
  { value: 2033, label: "2033" },
  { value: 2034, label: "2034" },
  { value: 2035, label: "2035" },
  { value: 2036, label: "2036" },
  { value: 2037, label: "2037" },
  { value: 2038, label: "2038" },
  { value: 2039, label: "2039" },
  { value: 2040, label: "2040" },
];
export const THEME_COLOR = "THEME_COLOR";

export const HORIZONTAL_NAVIGATION = "HORIZONTAL_NAVIGATION";

export const HORIZONTAL_MENU_POSITION = "HORIZONTAL_MENU_POSITION";
export const ABOVE_THE_HEADER = "ABOVE_THE_HEADER";
export const INSIDE_THE_HEADER = "INSIDE_THE_HEADER";
export const BELOW_THE_HEADER = "BELOW_THE_HEADER";

export const VERTICAL_NAVIGATION = "VERTICAL_NAVIGATION";
export const NAV_STYLE_MINI = "NAV_STYLE_MINI";

export const LAYOUT_TYPE = "LAYOUT_TYPE";

export const LAYOUT_TYPE_FRAMED = "LAYOUT_TYPE_FRAMED";
export const LAYOUT_TYPE_BOXED = "LAYOUT_TYPE_BOXED";
export const LAYOUT_TYPE_FULL = "LAYOUT_TYPE_FULL";

export const NAV_STYLE = "NAV_STYLE";

export const NAV_STYLE_FIXED = "NAV_STYLE_FIXED";
export const NAV_STYLE_MINI_SIDEBAR = "NAV_STYLE_MINI_SIDEBAR";
export const NAV_STYLE_DRAWER = "NAV_STYLE_DRAWER";
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR =
  "NAV_STYLE_NO_HEADER_MINI_SIDEBAR";
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR =
  "NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR";

export const NAV_STYLE_DEFAULT_HORIZONTAL = "NAV_STYLE_DEFAULT_HORIZONTAL";
export const NAV_STYLE_DARK_HORIZONTAL = "NAV_STYLE_DARK_HORIZONTAL";
export const NAV_STYLE_INSIDE_HEADER_HORIZONTAL =
  "NAV_STYLE_INSIDE_HEADER_HORIZONTAL";
export const NAV_STYLE_BELOW_HEADER = "NAV_STYLE_BELOW_HEADER";
export const NAV_STYLE_ABOVE_HEADER = "NAV_STYLE_ABOVE_HEADER";

export const LIGHT_PURPLE = "light_purple";
export const LIGHT_PURPLE_SEC = "#00B378";
export const LIGHT_PURPLE_DARK_TEXT_COLOR = "#9799AC";

export const RED = "red";
export const RED_SEC = "#00D9C9";
export const RED_DARK_TEXT_COLOR = "#878BAB";

export const BLUE = "blue";
export const BLUE_SEC = "#FCB53B";
export const BLUE_DARK_TEXT_COLOR = "#AAA59A";

export const DARK_BLUE = "dark_blue";
export const DARK_BLUE_SEC = "#17BDE5";
export const DARK_BLUE_DARK_TEXT_COLOR = "#9DDAE9";

export const ORANGE = "orange";
export const ORANGE_SEC = "#F1D065";
export const ORANGE_DARK_TEXT_COLOR = "#ABA895";

export const LIGHT_BLUE = "light_blue";
export const LIGHT_BLUE_SEC = "#59DCFF";
export const LIGHT_BLUE_DARK_TEXT_COLOR = "#92A2C8";

export const DEEP_ORANGE = "deep_orange";
export const DEEP_ORANGE_SEC = "#70A288";
export const DEEP_ORANGE_DARK_TEXT_COLOR = "#97B8C7";

export const LIGHT_PURPLE_1 = "light_purple_1";
export const LIGHT_PURPLE_1_SEC = "#E14594";
export const LIGHT_PURPLE_1_DARK_TEXT_COLOR = "#8288B4";

export const LIGHT_PURPLE_2 = "light_purple_2";
export const LIGHT_PURPLE_2_SEC = "#64D7D6";
export const LIGHT_PURPLE_2_DARK_TEXT_COLOR = "#5782BB";

export const TAB_SIZE = 992;
