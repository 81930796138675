import React from "react";
import { useDispatch /*, useSelector*/ } from "react-redux";
import { Avatar, Popover, Row, Col } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const history = useHistory();
  // const authUser = useSelector(({ auth }) => auth);
  // const stateData = useSelector((state) => state);
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("user_details"));
  // console.log("profile", userInfo?.user);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <button
          type="button"
          onClick={() => history.push("profile")}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          My Account
        </button>
      </li>
      {/* <li>Connections</li> */}
      <li>
        <button
          type="button"
          onClick={() => {
            dispatch(userSignOut());
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          Logout
        </button>
      </li>
    </ul>
  );
  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Row>
          <Col>
            {userInfo?.user?.profile_image !== undefined &&
            userInfo?.user?.profile_image !== "" ? (
              <img
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  padding: 2,
                  marginRight: 10,
                }}
                src={userInfo?.user?.profile_image}
                alt=""
              />
            ) : (
              <Avatar
                src={require("assets/images/avatar/domnic-harris.png")}
                className="gx-size-40 gx-pointer gx-mr-3"
                alt=""
              />
            )}
          </Col>
          <Col>
            <span className="gx-avatar-name">
              {userInfo?.user?.name}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
            <br />
          </Col>
        </Row>
      </Popover>
    </div>
  );
};

export default UserProfile;
