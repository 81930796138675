// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";

//dashboard categories
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

// all Permissions
export const ALL_PERMISSIONS = "ALL_PERMISSIONS";
export const DASHBOARD_ACCESS = "dashboard_access";
export const USER_MANAGEMENT_ACCESS = "user_management_access";
export const CATEGORY_CREATE = "category_create";
export const CATEGORY_EDIT = "category_edit";
export const CATEGORY_ACCESS = "category_access";
export const ROLE_CREATE = "role_create";
export const ROLE_EDIT = "role_edit";
export const ROLE_ACCESS = "role_access";
export const USER_CREATE = "user_create";
export const USER_EDIT = "user_edit";
export const USER_ACCESS = "user_access";
export const PRODUCT_CREATE = "product_create";
export const PRODUCT_EDIT = "product_edit";
export const PRODUCT_ACCESS = "product_access";
export const ADDRESS_BOOK_CREATE = "address_book_create";
export const ADDRESS_BOOK_EDIT = "address_book_edit";
export const ADDRESS_BOOK_ACCESS = "address_book_access";
export const VENDOR_CREATE = "vendor_create";
export const VENDOR_EDIT = "vendor_edit";
export const VENDOR_ACCESS = "vendor_access";
export const ORDER_MANAGEMENT_ACCESS = "order_management_access";
export const ORDER_CREATE = "order_create";
export const ORDER_EDIT = "order_edit";
export const ORDER_ACCESS = "order_access";
export const STORE_LOCATION_CREATE = "store_location_create";
export const STORE_LOCATION_EDIT = "store_location_edit";
export const STORE_LOCATION_ACCESS = "store_location_access";
export const REVIEW_ACCESS = "review_access";
export const REVIEW_DELETE = "review_delete";
export const OUR_PARTNER_CREATE = "our_partner_create";
export const OUR_PARTNER_EDIT = "our_partner_edit";
export const OUR_PARTNER_ACCESS = "our_partner_access";
export const OUR_PARTNER_DELETE = "our_partner_delete";
export const PURCHASE_ORDER_CREATE = "purchase_order_create";
export const PURCHASE_ORDER_EDIT = "purchase_order_edit";
export const PURCHASE_ORDER_ACCESS = "purchase_order_access";
export const PURCHASE_ORDER_DELETE = "purchase_order_delete";
export const COUPON_ACCESS = "coupon_access";
export const COUPON_CREATE = "coupon_create";
export const COUPON_EDIT = "coupon_edit";

export const OFFICE_SUPPLIES_ACCESS = "office_supplies_access";
export const OFFICE_SUPPLIES_CREATE = "office_supplies_create";
export const OFFICE_SUPPLIES_EDIT = "office_supplies_edit";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//Settings products for create Order
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";

// setting duplicate order
export const SET_DUPLICATE_ORDER = "SET_DUPLICATE_ORDER";

export const TEMPLATE_TEXTS = [
  {
    textArr: [
      "Award Of",
      "Excellence",
      "Presented To",
      "John Doe",
      "(company)",
      "(year)",
    ],
    //   data: data[0]
  },
  {
    textArr: [
      "Outstanding",
      "Achievement Award",
      "Presented to",
      "John Doe",
      "-For Your-",
      "Dedicated Service And",
      "Efforts",
      "Where They Counted Most",
      "(company)",
      "(year)",
    ],
    //   data: data[1]
  },
  {
    textArr: [
      "Award Of",
      "Excellence",
      "Presented To",
      "John Doe",
      "(company)",
      "(year)",
    ],
    //   data: data[0]
  },
  {
    textArr: [
      "Outstanding",
      "Achievement Award",
      "Presented to",
      "John Doe",
      "-For Your-",
      "Dedicated Service And",
      "Efforts",
      "Where They Counted Most",
      "(company)",
      "(year)",
    ],
    //   data: data[1]
  },
  {
    textArr: ["Design", "Your Own", "Engraving"],
    //   data: null
  },
];
