import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  SIGNOUT_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  // forgetPasswordSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
} from "../actions/Auth";
import { signIn, forgetPasswordApi, resetPasswordApi } from "../../dispatchAPI";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

// const signInUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((authUser) => authUser)
//     .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  // const {email, password} = payload;
  try {
    // const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    const signInUser = yield call(signIn, payload);
    if (signInUser.message) {
      yield put(
        showAuthMessage(
          signInUser?.response?.data?.errors?.password[0] ??
            signInUser?.response?.data?.errors?.email[0]
        )
      );
    } else {
      localStorage.setItem("user_id", signInUser.user.id);
      localStorage.setItem("user_type", signInUser.user.user_type);
      localStorage.setItem("role_id", signInUser.user.role.role_id);
      localStorage.setItem("token", signInUser.access_token);
      localStorage.setItem("user_details", JSON.stringify(signInUser));
      yield put(userSignInSuccess(signInUser.user.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* sendForgetPasswordApi({ payload }) {
  try {
    const result = yield call(forgetPasswordApi, payload);
    if (result.message) {
      // yield put(forgetPasswordSuccess());
      yield put(showAuthMessage(result.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* sendResetPasswordApi({ payload }) {
  try {
    const result = yield call(resetPasswordApi, payload);
    if (result.message) {
      // yield put(forgetPasswordSuccess());
      yield put(showAuthMessage(result.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      // yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    // yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* forgetPassword() {
  yield takeEvery(FORGET_PASSWORD, sendForgetPasswordApi);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, sendResetPasswordApi);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(forgetPassword),
    fork(resetPassword),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
  ]);
}
