import { all, fork, put, takeEvery } from "redux-saga/effects";
import { getCategoriesSuccess } from "../actions/Categories";
import { GET_CATEGORIES } from "../../constants/ActionTypes";
import { fetchError } from "../actions/Common";
import { getCategoriesApi } from "../../dispatchAPI";

function* fetchCategoriesRequest() {
  try {
    const fetchedCategories = yield put(getCategoriesApi);
    yield put(getCategoriesSuccess(fetchedCategories));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchCategories() {
  yield takeEvery(GET_CATEGORIES, fetchCategoriesRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchCategories)]);
}
