import axios from "axios";
import { API_ENDPOINT } from "../constants/Global";

const http = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    // "Content-type" : "application/json",
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    // "Authorization" : `Bearer ${localStorage.getItem('token')}`
  },
});

const setAuthorizationTokenWithRequest = () => {
  http.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
};

const signIn = async (payload) => {
  // console.log("email, password new file=>", payload);
  return await http
    .post("/login", payload)
    .then((authUser) => {
      // console.log("authUser in dispatchApi file");
      return authUser.data;
    })
    .catch((error) => error);
  // console.log(result)
  // .then((res)=>{
  //     console.log(res)
  //     // setToken(res.data.user,res.data.access_token);
  // }).catch((err) => {
  //     console.log(err, 'dsafa')
  // })
};

const forgetPasswordApi = async (payload) => {
  // console.log("email, password new file", payload);
  return await http
    .post("/forgot-password", payload)
    .then((authUser) => authUser.data)
    .catch((error) => error);
};

const resetPasswordApi = async (payload) => {
  return await http
    .post("/reset-password", payload)
    .then((authUser) => authUser.data)
    .catch((error) => error);
};

const getCategoriesApi = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-categories?page=${page}${params}`)
    .then((categories) => categories.data)
    .catch((error) => error);
};

const getParentCategoriesApi = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/get-parent-categories")
    .then((categories) => categories.data)
    .catch((error) => error);
};

const postAddCategory = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-category", payload)
    .then((res) => res)
    .catch((error) => error);
};

const saveOrderPayment = async (order_id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/add-order-partial-payment/${order_id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const deleteOrderPayment = async (payment_id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/delete-order-partial-payment/${payment_id}`)
    .then((res) => res)
    .catch((error) => error);
};

const postAddVariation = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-variation", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditCategory = async (id, payload) => {
  // console.log("postEditCategory in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/edit-category-by-id/" + id, payload)
    .then((res) => res)
    .catch((error) => error);
};

const toggleCategoryStatus = async (id, cb) => {
  // console.log("getParentCategoriesApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/toggle-category-status/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const toggleVariationStatus = async (id, payload) => {
  console.log("edit in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-variation-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getProductsApi = async (params, page) => {
  // console.log("getProductsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-products?page=${page}${params}`)
    .then((categories) => categories.data)
    .catch((error) => error);
};

const cloneProductApi = async (productId) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/copy-product/${productId}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getProductsForOrders = async (params) => {
  // console.log("getProductsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-product-list" + params)
    .then((res) => res.data)
    .catch((error) => error);
};

const toggleProductStatus = async (id, cb) => {
  // console.log("toggleProductStatus in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/toggle-product-status/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const postAddProduct = async (payload) => {
  // console.log("postAddProduct in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-product", payload)
    .then((res) => res)
    .catch((error) => error);
};

const getVendorsApi = async (payload) => {
  // console.log("getVendorsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-vendors")
    .then((vendors) => vendors.data)
    .catch((error) => error);
};
const getActiveVendorsApi = async () => {
  // console.log("getVendorsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-active-vendors")
    .then((vendors) => vendors.data)
    .catch((error) => error);
};

const getVariationsApi = async (params, pageNo) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-variations?page=${pageNo}${params}`)
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getActiveVariationsApi = async () => {
  // console.log("getVariationsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-active-variations`)
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getContactApi = async (params, pageNo) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/contact-queries?page=${pageNo}${params}`)
    .then((contact) => contact.data)
    .catch((error) => error);
};

const deleteContactApi = async (id) => {
  // console.log("getVariationsApi in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .delete(`/admin/delete-contact-query-by-id/${id}`)
    .then((contact) => contact.data)
    .catch((error) => error);
};

const getCouponApi = async (params, pageNo) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-coupons?page=${pageNo}${params}`)
    .then((response) => response.data)
    .catch((error) => error);
};
const getCouponDetailApi = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-coupon-by-id/${id}`)
    .then((response) => response.data)
    .catch(() => null);
};
const saveCouponApi = async (payload) => {
  setAuthorizationTokenWithRequest();
  return http
    .post(`/admin/add-coupon`, payload)
    .then((response) => response.data)
    .catch((error) => error);
};
const updateCouponApi = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return http
    .post(`/admin/edit-coupon-by-id/${id}/?_method=PUT`, payload)
    .then((response) => response.data)
    .catch((error) => error);
};

const getShopOrdersApi = async (params, pageNo) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-office-supplies?page=${pageNo}${params}`)
    .then((response) => response.data)
    .catch((error) => error);
};
const getActiveShopOrdersApi = async () => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-active-office-supplies`)
    .then((response) => response.data)
    .catch((error) => error);
};
const getShopOrderDetailApi = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-office-supply-by-id/${id}`)
    .then((response) => response.data)
    .catch(() => null);
};
const saveShopOrderApi = async (payload) => {
  setAuthorizationTokenWithRequest();
  return http
    .post(`/admin/add-office-supply`, payload)
    .then((response) => response.data)
    .catch((error) => error);
};
const updateShopOrderApi = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return http
    .post(`/admin/edit-office-supply-by-id/${id}/`, payload)
    .then((response) => response.data)
    .catch((error) => error);
};

const getEditOrderApi = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-order-info-by-id/${id}`)
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getJobsComparisonMonthYear = async (reportType, from, to, date) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/jobs-completed-comparison-report?year_from=${from}&year_to=${to}&report_type=${reportType}&date=${date}`;
  if (reportType === "custom") {
    url = `/admin/jobs-completed-comparison-report?year_from=${from}&year_to=${to}&report_type=${reportType}`;
  }
  if (reportType === "weekly" || reportType === "daily") {
    url = `/admin/jobs-completed-comparison-report?report_type=${reportType}&date=${date}`;
  }
  return await http
    .get(url)
    .then((variations) => variations.data)
    .catch((error) => error);
};
const getOutstandingOrders = async (reportType, userName, statusId) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/outstanding-orders-report?report_type=${reportType}&name=${userName}&status_id=${statusId}`;

  return await http
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};

const getOutstandingOrdersDownload = async (
  reportType = "all",
  userName,
  statusId
) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/outstanding-orders-report-pdf?report_type=${reportType}&name=${userName}&status_id=${statusId}`;

  return await http
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};
const getSalesExemptionReportDownload = async (
  reportType = "all",
  resale_number = "",
  waive_off_sales_tax_reason = ""
) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/sales-tax-exemption-report-pdf?report_type=${reportType}`;
  if (resale_number !== "") {
    url = `${url}&resale_number=${resale_number}`;
  }
  if (waive_off_sales_tax_reason !== "") {
    url = `${url}&waive_off_sales_tax_reason=${waive_off_sales_tax_reason}`;
  }
  return await http
    .get(url)
    .then((variations) => variations.data)
    .catch((error) => error);
};
const getSalesTaxSummarizationReport = async (filters) => {
  setAuthorizationTokenWithRequest();

  return await http
    .get(`/admin/sales-tax-summarization-report${filters ? "?" + filters : ""}`)
    .then((res) => res.data)
    .catch((error) => error);
};
const getSalesTaxSummarizationReportDownload = async (filters) => {
  setAuthorizationTokenWithRequest();

  return await http
    .get(
      `/admin/sales-tax-summarization-report-pdf${filters ? "?" + filters : ""}`
    )
    .then((res) => res.data)
    .catch((error) => error);
};

const getSalesExemptionReport = async (
  reportType = "all",
  resale_number = "",
  waive_off_sales_tax_reason = ""
) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/sales-tax-exemption-report?report_type=${reportType}`;
  if (resale_number !== "") {
    url = `${url}&resale_number=${resale_number}`;
  }
  if (waive_off_sales_tax_reason !== "") {
    url = `${url}&waive_off_sales_tax_reason=${waive_off_sales_tax_reason}`;
  }
  return await http
    .get(url)
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getJobsComparisonMonthYearDownload = async (
  reportType,
  from,
  to,
  date
) => {
  setAuthorizationTokenWithRequest();
  let url = `/admin/jobs-completed-comparison-report-pdf?year_from=${from}&year_to=${to}&report_type=${reportType}&date=${date}`;
  if (reportType === "custom") {
    url = `/admin/jobs-completed-comparison-report-pdf?year_from=${from}&year_to=${to}&report_type=${reportType}`;
  }
  if (reportType === "weekly" || reportType === "daily") {
    url = `/admin/jobs-completed-comparison-report-pdf?report_type=${reportType}&date=${date}`;
  }
  return await http
    .get(url)
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getSalesTaxComparisonMonthYear = async (reportType, from, to) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/sales-tax-comparison-report?year_from=${from}&year_to=${to}&report_type=${reportType}`
    )
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getSalesTaxComparisonMonthYearDownload = async (reportType, from, to) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/sales-tax-comparison-report-pdf?year_from=${from}&year_to=${to}&report_type=${reportType}`
    )
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getSalesComparisonMonthYear = async (reportType, from, to) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/sales-comparison-report?year_from=${from}&year_to=${to}&report_type=${reportType}`
    )
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getSalesComparisonMonthYearDownload = async (reportType, from, to) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/sales-comparison-report-pdf?year_from=${from}&year_to=${to}&report_type=${reportType}`
    )
    .then((variations) => variations.data)
    .catch((error) => error);
};

const getProductById = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-product-by-id/" + id)
    .then((res) => res.data)
    .catch((error) => error);
};
const checkCombinationInAnyOrder = async (price_id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/check-combination-in-order/${price_id}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getProductByIdForCreate = async (id) => {
  // console.log("getProductByIdForCreate in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/get-product-by-id/" + id)
    .then((res) => res.data)
    .catch((error) => error);
};

const editProductByIdInOrder = async (id, payload) => {
  // console.log("getProductByIdForCreate in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/edit-order-by-id/" + id, payload)
    .then((res) => res.data)
    .catch((error) => error);
};

const getMarkPurchaseOrderReceived = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/mark-purchase-order-received/" + id)
    .then((res) => res)
    .catch((error) => error);
};
const editPurchaseOrderAdminNotes = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-purchase-order-admin-notes/${id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllVendors = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-vendors?page=${page}${params}`)
    .then((vendors) => vendors.data)
    .catch((error) => error);
};

const getAllEmployees = async (params = "") => {
  // console.log("getAllEmployees in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-employees" + params)
    .then((vendors) => vendors.data)
    .catch((error) => error);
};

const postAddVendor = async (payload) => {
  // console.log("postAddVendor in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-vendor", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditVendor = async (id, payload) => {
  // console.log("postEditVendor in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-vendor-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllUsers = async (params, page, noPaging) => {
  setAuthorizationTokenWithRequest();
  noPaging = noPaging || false;
  return await http
    .get(`/admin/get-all-users?page=${page}${params}&no_paging=${noPaging}`)
    .then((users) => users.data)
    .catch((error) => error);
};

const getTopSellingProducts = async (field, order, filters) => {
  setAuthorizationTokenWithRequest();
  return await http
    // .get(`/admin/top-selling-products${filters}`)
    .get(`/admin/top-selling-products-v2/${field}/${order}${filters}`)
    .then((res) => res.data)
    .catch((error) => error);
};
const downloadTopSellingProducts = async (field, order, filters) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/top-selling-products-pdf-v2/${field}/${order}${filters}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getWaiveOffReasons = async () => {
  // console.log("getAllUsers in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-waive-off-reasons")
    .then((users) => users.data)
    .catch((error) => error);
};

const toggleUserStatus = async (id, cb) => {
  // console.log("toggleUserStatus in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/toggle-user-status/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const postEditProduct = async (id, payload) => {
  // console.log("postEditProduct in dispatchAPI file", id, payload);
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/edit-product-by-id/" + id, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAddressBookList = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/address-books?page=${page}${params}`)
    .then((addressBookData) => addressBookData.data)
    .catch((error) => error);
};

const getAllStoreLocations = async (params, page, noPaging) => {
  noPaging = noPaging || false;

  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/get-all-store-locations?page=${page}${params}&no_paging=${noPaging}`
    )
    .then((storeLocations) => storeLocations.data)
    .catch((error) => error);
};

const getUserVaultAddress = async (params = "") => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-customer-vaults?user_id=` + params)
    .then((storeLocations) => storeLocations.data)
    .catch((error) => error);
};

const getAllStockLocations = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-stock-locations?page=${page}${params}`)
    .then((storeLocations) => storeLocations.data)
    .catch((error) => error);
};

const postAddStoreLocation = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-store-location", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postAddStockLocation = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-stock-location", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditStoreLocation = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-store-location-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditStockLocation = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-stock-location-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllPartners = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-partner-list?page=${page}${params}`)
    .then((partners) => partners.data)
    .catch((error) => error);
};

const postAddPartner = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-partner", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditPartner = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-partner-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getUserDetails = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-user-by-id/${id}`)
    .then((res) => res)
    .catch((error) => error);
};

const checkItemQty = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/check-item-price-change`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postAddAddressBook = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/add-address-book", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditAddressBook = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/update-address-book-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllRoles = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-roles")
    .then((categories) => categories.data)
    .catch((error) => error);
};

const postAddUser = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-user", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditUser = async (id, payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-user-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getRoleById = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-role-by-id/" + id)
    .then((roles) => roles.data)
    .catch((error) => error);
};

const getAllPermissions = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-permissions")
    .then((permissions) => permissions.data)
    .catch((error) => error);
};

const postAddRole = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/add-role", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditRole = async (id, payload) => {
  // console.log("postEditRole in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-role-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const deletePartner = async (id, cb) => {
  // console.log("deletePartner in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .delete("/admin/delete-partner-by-id/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const getDashboardData = async () => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/dashboard")
    .then((dashboard) => dashboard.data)
    .catch((error) => error);
};
const getContactQuery = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-contact-query-by-id/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

const getSalesTaxReport = async (query) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/sales-tax-report?report_type=${query}`)
    .then((dashboard) => dashboard.data)
    .catch((error) => error);
};

const getSalesReport = async (query) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/sales-report?report_type=${query}`)
    .then((dashboard) => dashboard.data)
    .catch((error) => error);
};

const getJobCompleted = async (query) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/jobs-completed-report?report_type=${query}`)
    .then((dashboard) => dashboard.data)
    .catch((error) => error);
};

const getSalesYearOverYear = async (reportType, yearFrom, yearTo) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(
      `/admin/year-over-year-report?report_type=${reportType}&year_to=${yearTo}&year_from=${yearFrom}`
    )
    .then((dashboard) => dashboard.data)
    .catch((error) => error);
};

const getOrdersList = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-orders?page=${page}${params}`)
    .then((allOrders) => allOrders.data)
    .catch((error) => error);
};

const getOrderByID = async (id) => {
  // console.log("getOrderByID in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-order-by-id/" + id)
    .then((order) => order.data)
    .catch((error) => error);
};

const assignOrderEmployee = async (id, payload) => {
  // console.log("getOrderByID in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/assign-order/${id}`, payload)
    .then((order) => order.data)
    .catch((error) => error);
};

const postAddOrderNote = async (id, payload) => {
  // console.log("postAddOrderNote in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/add-order-note/${id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postUpdateOrderStatus = async (id, payload) => {
  // console.log("postUpdateOrderStatus in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/update-order-status/${id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const deleteOrderByID = async (id, cb) => {
  // console.log("deleteOrderByID in dispatchAPI file", id);
  setAuthorizationTokenWithRequest();
  return await http
    .delete("/admin/delete-order-by-id/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const getDuplicateOrderByID = async (id) => {
  // console.log("getDuplicateOrderByID in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/copy-order/" + id)
    .then((order) => order)
    .catch((error) => error);
};

const getEmployees = async () => {
  // console.log("getEmployees in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-employees")
    .then((employees) => employees.data)
    .catch((error) => error);
};

const getWorkOrder = async (id) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/work-order/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const postAssignOrderToEmployee = async (id, payload) => {
  // console.log("postAssignOrderToEmployee in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/assign-order/${id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllReviews = async (params, page) => {
  // console.log("getAllReviews in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-reviews?page=${page}${params}`)
    .then((reviews) => reviews.data)
    .catch((error) => error);
};

const getAllInvoices = async () => {
  // console.log("getAllInvoices in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-invoices")
    .then((invoices) => invoices.data)
    .catch((error) => error);
};

const deleteReviewByID = async (id, cb) => {
  // console.log("deleteReviewByID in dispatchAPI file", id);
  setAuthorizationTokenWithRequest();
  return await http
    .delete("/admin/delete-review-by-id/" + id)
    .then((res) => cb(res.data))
    .catch((error) => error);
};

const getUserTypes = async () => {
  // console.log("getUserTypes in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-user-types")
    .then((userTypes) => userTypes.data)
    .catch((error) => error);
};

const verifyCoupon = async (coupon) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/verify-coupon/${coupon}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getAvailableOrderStatus = async () => {
  // console.log("getAvailableOrderStatus in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-available-order-status")
    .then((orderStatus) => orderStatus.data)
    .catch((error) => error);
};

const getOrderNotes = async (id) => {
  // console.log("getOrderNotes in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-order-notes/" + id)
    .then((orderNotes) => orderNotes)
    .catch((error) => error);
};

const getSendInvoice = async (id) => {
  // console.log("getSendInvoice in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/send-order-invoice/" + id)
    .then((sendInvoice) => sendInvoice)
    .catch((error) => error);
};

const getGenerateOrderInvoice = async (id) => {
  // console.log("getGenerateOrderInvoice in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/generate-order-invoice/" + id)
    .then((generateOrderInvoice) => generateOrderInvoice)
    .catch((error) => error);
};

const waiveOffOrderTax = async (id, payload, cb) => {
  // console.log("waiveOffOrderTax in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/waive-off-order-tax/${id}`, payload)
    .then((res) => cb(res.data.data))
    .catch((error) => error);
};

const getPaymentTypes = async () => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-payment-types")
    .then((paymentTypes) => paymentTypes.data)
    .catch((error) => error);
};

const postAddOrder = async (payload, cb) => {
  // console.log("postAddOrder in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/add-order`, payload)
    .then((res) => res.data)
    .catch((error) => error);
};

const getVaultAddress = async () => {
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-my-vaults")
    .then((vaultAddress) => vaultAddress.data)
    .catch((error) => error);
};

const getShippingDetails = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/get-shipping-quote`, payload)
    .then((res) => res.data)
    .catch((error) => error);
};

const validateQuantity = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/validate-product-quantity`, payload)
    .then((res) => res.data)
    .catch((error) => error);
};

const postEditStateSalesTax = async (id, payload) => {
  // console.log("postEditStateSalesTax in dispatchAPI file", id, payload);
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/update-state-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postEditSiteSettings = async (id, payload) => {
  // console.log("postEditSiteSettings in dispatchAPI file", id, payload);
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/edit-site-setting-by-id/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postAddSiteSettings = async (payload) => {
  // console.log("postAddSiteSettings in dispatchAPI file", payload);
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/add-site-setting`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const getAllStateSalesTax = async (params, page, noPaging) => {
  noPaging = noPaging || false;
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-states?page=${page}${params}&no_paging=${noPaging}`)
    .then((stateSalesTax) => stateSalesTax.data)
    .catch((error) => error);
};

const getSiteSettings = async (params = "") => {
  // console.log("getSiteSettings in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-all-site-settings" + params)
    .then((siteSettings) => siteSettings.data)
    .catch((error) => error);
};

const postUpdateAdminPassword = async (payload) => {
  // console.log("postUpdateAdminPassword in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/change-password", payload)
    .then((res) => res)
    .catch((error) => error);
};

const postUpdateOrderQuoteStatus = async (id, payload) => {
  // console.log("postUpdateOrderQuoteStatus in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/update-order-quote-status/${id}`, payload)
    .then((res) => res)
    .catch((error) => error);
};

const postAddProductToOrder = async (payload) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post(`/admin/add-products-to-order`, payload)
    .then((res) => res.data.data)
    .catch((error) => error);
};

const getAllPurchaseOrder = async (params, page) => {
  setAuthorizationTokenWithRequest();
  return await http
    .get(`/admin/get-all-purchase-orders?page=${page}${params}`)
    .then((allPurchaseOrders) => allPurchaseOrders.data)
    .catch((error) => error);
};

const getPurchaseOrderItems = async () => {
  // console.log("getPurchaseOrderItems in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-purchase-order-items")
    .then((purchaseOrderItems) => purchaseOrderItems.data)
    .catch((error) => error);
};

const getPurchaseOrderStatus = async () => {
  // console.log("getPurchaseOrderItems in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-purchase-order-statuses")
    .then((purchaseOrderItems) => purchaseOrderItems.data)
    .catch((error) => error);
};

const getShippingSpeed = async () => {
  // console.log("getShippingSpeed in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-shipping-speed")
    .then((shippingSpeed) => shippingSpeed.data)
    .catch((error) => error);
};

const getPaymentTerms = async () => {
  // console.log("getPaymentTerms in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-payment-terms")
    .then((purchaseOrderItems) => purchaseOrderItems.data)
    .catch((error) => error);
};

const getPurchaseOrderAddresses = async () => {
  // console.log("getPurchaseOrderAddresses in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-purchase-order-addresses")
    .then((purchaseOrderAddresses) => purchaseOrderAddresses.data)
    .catch((error) => error);
};

const postCreatePurchaseOrder = async (payload) => {
  // console.log("postCreatePurchaseOrder in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/create-purchase-order", payload)
    .then((res) => res)
    .catch((error) => error);
};

const getPurchaseOrderDetails = async (id) => {
  // console.log("getPurchaseOrderDetails in dispatchAPI file");
  setAuthorizationTokenWithRequest();
  return await http
    .get("/admin/get-purchase-order-details/" + id)
    .then((orderDetails) => orderDetails.data)
    .catch((error) => error);
};

const removePurchaseOrderItem = async (body) => {
  setAuthorizationTokenWithRequest();
  return await http
    .post("/admin/delete-purchase-order-item", body)
    .then((orderDetails) => orderDetails)
    .catch((error) => error);
};

export {
  removePurchaseOrderItem,
  setAuthorizationTokenWithRequest,
  signIn,
  forgetPasswordApi,
  resetPasswordApi,
  getCategoriesApi,
  getParentCategoriesApi,
  postAddCategory,
  postEditCategory,
  toggleCategoryStatus,
  getProductsApi,
  checkCombinationInAnyOrder,
  cloneProductApi,
  getProductsForOrders,
  getEditOrderApi,
  toggleProductStatus,
  postAddProduct,
  saveOrderPayment,
  deleteOrderPayment,
  postAddVariation,
  toggleVariationStatus,
  getVendorsApi,
  getActiveVendorsApi,
  getVariationsApi,
  getProductById,
  getAllVendors,
  getContactApi,
  deleteContactApi,
  getCouponApi,
  saveCouponApi,
  getCouponDetailApi,
  updateCouponApi,
  getShopOrdersApi,
  getActiveShopOrdersApi,
  getShopOrderDetailApi,
  saveShopOrderApi,
  updateShopOrderApi,
  getAllEmployees,
  postAddVendor,
  postEditVendor,
  getAllUsers,
  toggleUserStatus,
  postEditProduct,
  getAddressBookList,
  getAllStoreLocations,
  getAllPartners,
  postAddStoreLocation,
  postEditStoreLocation,
  postAddPartner,
  postEditPartner,
  getUserDetails,
  checkItemQty,
  postAddAddressBook,
  postEditAddressBook,
  getActiveVariationsApi,
  getAllRoles,
  postAddUser,
  postEditUser,
  getRoleById,
  getAllPermissions,
  postAddRole,
  postEditRole,
  deletePartner,
  getDashboardData,
  getContactQuery,
  getOrdersList,
  editProductByIdInOrder,
  getOrderByID,
  postAddOrderNote,
  deleteOrderByID,
  getDuplicateOrderByID,
  getEmployees,
  getWorkOrder,
  postAssignOrderToEmployee,
  getAllReviews,
  deleteReviewByID,
  getUserTypes,
  verifyCoupon,
  getAvailableOrderStatus,
  postUpdateOrderStatus,
  getOrderNotes,
  getProductByIdForCreate,
  getAllStockLocations,
  postAddStockLocation,
  postEditStockLocation,
  waiveOffOrderTax,
  getPaymentTypes,
  postAddOrder,
  getAllStateSalesTax,
  postEditStateSalesTax,
  getSiteSettings,
  postUpdateAdminPassword,
  postUpdateOrderQuoteStatus,
  postAddProductToOrder,
  getAllPurchaseOrder,
  getPurchaseOrderItems,
  getShippingSpeed,
  getPaymentTerms,
  getPurchaseOrderAddresses,
  postCreatePurchaseOrder,
  getPurchaseOrderDetails,
  getAllInvoices,
  getSendInvoice,
  getGenerateOrderInvoice,
  getMarkPurchaseOrderReceived,
  editPurchaseOrderAdminNotes,
  postEditSiteSettings,
  postAddSiteSettings,
  validateQuantity,
  getShippingDetails,
  getSalesTaxReport,
  getSalesReport,
  getJobCompleted,
  getSalesYearOverYear,
  getSalesComparisonMonthYear,
  getSalesComparisonMonthYearDownload,
  getSalesTaxComparisonMonthYearDownload,
  getSalesTaxComparisonMonthYear,
  getJobsComparisonMonthYear,
  getOutstandingOrders,
  getOutstandingOrdersDownload,
  getSalesExemptionReport,
  getJobsComparisonMonthYearDownload,
  getPurchaseOrderStatus,
  getVaultAddress,
  getUserVaultAddress,
  getTopSellingProducts,
  downloadTopSellingProducts,
  getWaiveOffReasons,
  getSalesExemptionReportDownload,
  getSalesTaxSummarizationReport,
  getSalesTaxSummarizationReportDownload,
  assignOrderEmployee,
};
